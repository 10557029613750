function School(props) {

    return(

        <div class="education-group">
            <strong>
                {props.heading}
            </strong>
            <p class="school">
                {props.school}
            </p>
        </div>

    )

}

export default School;
