import BulletsRow from "./BulletsRow"

function Strengths(props) {

    return(

        <div id="Strengths" class="section">
            <h1 class="section-heading">{props.header}</h1>
            <BulletsRow 
                listItem1={props.strength1}
                listItem2={props.strength2}
                listItem3={props.strength3}
            />
            <BulletsRow
                listItem1={props.strength4}
                listItem2={props.strength5}
                listItem3={props.strength6}
            />
            <BulletsRow
                listItem1={props.strength7}
                listItem2={props.strength8}
                listItem3={props.strength9}
            />
        </div>

    )

}

export default Strengths;
