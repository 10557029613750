function Experience(props) {

    return(

        <div class="section">
            <h1 class="section-heading">{props.header}</h1>
            <div class="sub-section">
                <div class="job-header">
                    <p class="job-location"><strong>{props.titleHeading1}</strong></p>
                    <p class="job-title"><strong>{props.companyHeading1}</strong></p>
                    <p class="job-company">{props.locationHeading1}</p>
                    <p class="job-date">{props.datesHeading1}</p>
                    <ul class="job-desc">
                        <li class="job-desc-item">{props.jobDesc1}</li>
                        <li class="job-desc-item">{props.jobDesc2}</li>
                    </ul>
                </div>
            </div>
            <div class="sub-section">
                <div class="job-header">
                    <p class="job-location"><strong>{props.titleHeading2}</strong></p>
                    <p class="job-title"><strong>{props.companyHeading2}</strong></p>
                    <p class="job-company">{props.locationHeading2}</p>
                    <p class="job-date">{props.datesHeading2}</p>
                    <ul class="job-desc">
                        <li class="job-desc-item">{props.jobDesc3}</li>
                        <li class="job-desc-item">{props.jobDesc4}</li>
                        <li class="job-desc-item">{props.jobDesc5}</li>
                    </ul>
                </div>
            </div>
            <div class="sub-section">
                <div class="job-header">
                    <p class="job-location"><strong>{props.titleHeading3}</strong></p>
                    <p class="job-title"><strong>{props.companyHeading3}</strong></p>
                    <p class="job-company">{props.locationHeading3}</p>
                    <p class="job-date">{props.datesHeading3}</p>
                    <ul class="job-desc">
                        <li class="job-desc-item">{props.jobDesc6}</li>
                        <li class="job-desc-item">{props.jobDesc7}</li>
                        <li class="job-desc-item">{props.jobDesc8}</li>
                    </ul>
                </div>
            </div>
            <div class="sub-section">
                <div class="job-header">
                    <p class="job-location"><strong>{props.titleHeading4}</strong></p>
                    <p class="job-title"><strong>{props.companyHeading4}</strong></p>
                    <p class="job-company">{props.locationHeading4}</p>
                    <p class="job-date">{props.datesHeading4}</p>
                    <ul class="job-desc">
                        <li class="job-desc-item">{props.jobDesc9}</li>
                    </ul>
                </div>
            </div>
            <div class="sub-section">
                <div class="job-header">
                    <p class="job-location"><strong>{props.titleHeading5}</strong></p>
                    <p class="job-title"><strong>{props.companyHeading5}</strong></p>
                    <p class="job-company">{props.locationHeading5}</p>
                    <p class="job-date">{props.datesHeading5}</p>
                    <ul class="job-desc">
                        <li class="job-desc-item">{props.jobDesc10}</li>
                        <li class="job-desc-item">{props.jobDesc11}</li>
                    </ul>
                </div>
            </div>
            <div class="sub-section">
                <div class="job-header">
                    <p class="job-location"><strong>{props.titleHeading6}</strong></p>
                    <p class="job-title"><strong>{props.companyHeading6}</strong></p>
                    <p class="job-company">{props.locationHeading6}</p>
                    <p class="job-date">{props.datesHeading6}</p>
                    <ul class="job-desc">
                        <li class="job-desc-item">{props.jobDesc12}</li>
                        <li class="job-desc-item">{props.jobDesc13}</li>
                        <li class="job-desc-item">{props.jobDesc14}</li>
                        <li class="job-desc-item">{props.jobDesc15}</li>
                    </ul>
                </div>
            </div>
            <div class="sub-section">
                <div class="job-header">
                    <p class="job-location"><strong>{props.titleHeading7}</strong></p>
                    <p class="job-title"><strong>{props.companyHeading7}</strong></p>
                    <p class="job-company">{props.locationHeading7}</p>
                    <p class="job-date">{props.datesHeading7}</p>
                    <ul class="job-desc">
                        <li class="job-desc-item">{props.jobDesc16}</li>
                        <li class="job-desc-item">{props.jobDesc17}</li>
                    </ul>
                </div>
            </div>
            <div class="sub-section">
                <div class="job-header">
                    <p class="job-location"><strong>{props.titleHeading8}</strong></p>
                    <p class="job-title"><strong>{props.companyHeading8}</strong></p>
                    <p class="job-company">{props.locationHeading8}</p>
                    <p class="job-date">{props.datesHeading8}</p>
                    <ul class="job-desc">
                        <li class="job-desc-item">{props.jobDesc18}</li>
                        <li class="job-desc-item">{props.jobDesc19}</li>
                        <li class="job-desc-item">{props.jobDesc20}</li>
                        <li class="job-desc-item">{props.jobDesc21}</li>
                    </ul>
                </div>
            </div>

        </div>

    )

}

export default Experience;
