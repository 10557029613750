import "./App.css"
import Heading from "./components/Heading"
import Summary from "./components/Summary"
import Strengths from "./components/Strengths"
import Competencies from "./components/Competencies"
import Education from "./components/Education"
import Experience from "./components/Experience"

function App() {
  return (
    <div id="Main">

      <Heading 
        name="Derek E. Evans"
      />

      <Summary 
        header="Professional Summary" 
        body="
          Experienced business professional with over 13 years of project 
          management and engineering experience in the utility industry and 2 years 
          of experience utilizing IBM Maximo in the execution of design, cost estimating, 
          and project management job functions. Self-starter with excellent data and 
          analytics skills adept to communicating complex information to both technical 
          and non-technical audience members in fast-paced environments."
      />

      <Strengths 
        header="Demonstrated Strengths"
        strength1="Python Programming"
        strength2="Project Management"
        strength3="ERP Systems"
        strength4="Microsoft Excel"
        strength5="Power BI"
        strength6="SAP"
        strength7="Problem Solving"
        strength8="Snowflake Data Platform"
        strength9="Google Cloud"
      />

      <Experience
        header="Professional Experience"
        companyHeading1="Gordon Food Service"
        locationHeading1="Wyoming, MI"
        datesHeading1="May 2024-Current"
        titleHeading1="Business Intelligence Analyst"
        jobDesc1="Developing and deploying dashboard reporting tools."
        jobDesc2="Reviewing existing datasets for quality improvement and data expansion opportunities using 
                  SQL in support of on-going data lake development and deployment."
        companyHeading2="Bartech Staffing"
        locationHeading2="Vero Beach, FL"
        datesHeading2="Feb 2023-Aug 2023"
        titleHeading2="Budget & Process Analyst"
        jobDesc3="Reviews purchase orders and amendments to perform project budget allocations."
        jobDesc4="Compiles and maintains data from multiple ERP & EAM systems to on-going project 
                  cost tracking and reporting including Maximo EAM and work order tracking."
        jobDesc5="Develops reporting tools using Microsoft Excel & Power BI to support tracking 
                  and reporting needs for various project budget and scheduling activities."
        companyHeading3="Pike Engineering"
        locationHeading3="Springdale, OH"
        datesHeading3="May 2022 - Oct 2022"
        titleHeading3="Regional Business Analyst"
        jobDesc6="Developed a cloud-based work management application for district engineering office 
                  on a low-code platform called Quickbase using the platform’s native development tools 
                  and REST API, along with Visual Studio Code and Microsoft Excel."
        jobDesc7="Developed a solution to improve the UX for an existing internally developed Quickbase 
                  application by writing a custom Python script to automate redundant manual data entry 
                  tasks using Microsoft VS Code and the Quickbase REST API based on user feedback from 
                  a Project Analyst from another district."
        jobDesc8="Developed new application enhancement features for existing internally developed Quickbase 
                  application that was developed to collect QA/QC data as part of the requesting districts 
                  design review process by building aggregation tables summarizing design quality data used 
                  for visualizations that specifically identify areas for improvement at both a team and 
                  individual employee level. This work was performed based on feedback from the requesting 
                  districts Project Analyst."
        companyHeading4="Duke Energy"
        locationHeading4="Columbus, IN"
        datesHeading4="Oct 2021 – May 2022"
        titleHeading4="Senior Engineering Technologist"
        jobDesc9="Managed and executed the design review process for over 500 pole replacement projects in 
                  support of statewide grid reliability improvement initiative leading to improved quality 
                  of IBM Maximo data and engineering standards compliance."
        companyHeading5="Colorado Springs Utilities"
        locationHeading5="Colorado Springs, CO"
        datesHeading5="Apr 2020 – Sep 2021"
        titleHeading5="Project Manager & Field Engineering Support Coordinator"
        jobDesc10="Planned and designed the solution to a power quality issue using AutoCAD Utility Design 
                  and IBM Maximo upon completion of historical electric load research and data analysis 
                  performed using a custom developed Microsoft Excel application that quantified the need 
                  for corrective actions and proactively mitigated the risk of future damage to both internally 
                  and externally owned electrical equipment."
        jobDesc11="Managed field engineering activities leading to the successful deployment of the organizations 
                  AMI (Advanced Metering Infrastructure) technology by performing project planning and 
                  coordination duties throughout entire project life cycle in collaboration with internal 
                  project leader."
        companyHeading6="Montana-Dakota Utilities Company"
        locationHeading6="Williston, ND"
        datesHeading6="Feb 2014 - Jul 2018"
        titleHeading6="Engineering Project Manager"
        jobDesc12="Planned and designed electric distribution construction projects for new installations 
                   and system improvements, utilizing various software systems including ArcGIS Silverlight, 
                   Oracle Customer Care & Billing for Utilities, PowerPlan, JD Edwards, and various internally 
                   developed software applications."
        jobDesc13="Managed, scheduled, planned, and prioritized work for electric line construction crew 
                   operating from a satellite facility within our district."
        jobDesc14="Planned and coordinated short-lead time work for multiple electric service technicians 
                   throughout the operating district."
        jobDesc15="Provided technical support to design and construction personnel in support of operational 
                   effectiveness."
        companyHeading7="Xcel Energy"
        locationHeading7="Multiple Locations"
        datesHeading7="Aug 2011 - May 2013"
        titleHeading7="Electrical Distribution Designer"
        jobDesc16="Planned and designed electric distribution construction projects for new installations 
                   and system improvements, utilizing various software systems including, Smallworld GIS, 
                   Bentley Expert Designer, ABB Service Suite, and internal CRS system."
        jobDesc17="Managed multiple projects from initial customer request through completion of construction, 
                   resulting in the installation of new electrical services, relocation of existing electrical 
                   utility lines, and the removal of existing electrical lines for residential, commercial, 
                   and industrial customers."
        companyHeading8="Consumers Energy"
        locationHeading8="Kentwood, MI"
        datesHeading8="Jun 2005 - Nov 2010"
        titleHeading8="Customer Energy Specialist II"
        jobDesc18="Performed all aspects of construction design as it relates to present and future gas and electrical utility needs using Bentley MicroStation, internal GIS applications, and legacy mapping systems."
        jobDesc19="Conducted meetings with clients, including homeowners, real estate developers, contractors, local government entities, and business clients of all sizes."
        jobDesc20="Reviewed construction plans to estimate proposed construction labor and material requirements."
        jobDesc21="Managed multiple projects from initial customer request through completion of construction utilizing SAP Business Software."
      />

      <Education 
        header="Education" 
        subHeading1="Associate of Applied Science - Electronics Technology"
        body1="Baker College - Owosso, MI"
        subHeading2="Microsoft Office Specialist: Excel Expert"
        body2="Microsoft, 2020"
      />

      <Competencies
        header="Competencies"
        subHeader1="Highlighted Skills"
        competency1="Project Management"
        competency2="Project Planning"
        competency3="Process Improvement"
        competency4="Data Analysis"
        competency5="ETL Processes"
        competency6="Computational Logic"
        competency7="Business Intelligence"
        competency8="Problem Solving"
        competency9="Data Modeling"
        competency10="Data Cleansing"
        competency11="Data Visualization"
        competency12="Data Management"
        subHeader2="Software & Technology:"
        competency13="Quickbase"
        competency14="AWS Lambda"
        competency15="AWS Event Bridge"
        competency16="AWS Secrets Manager"
        competency17="AWS Amplify"
        competency18="Azure SQL"
        competency19="Azure Blob Storage"
        competency20="GitHub"
        competency21="REST APIs"
        competency22="Power BI"
        competency23="Adobe Acrobat"
        competency24="VS Code"
        competency25="Tableau"
        competency26="Notion"
        competency27="Camtasia"
        competency28="Power Query"
        competency29="Visio"
        competency30="Snowflake Data Cloud"
        competency31="Google Cloud"
        competency32="Google BigQuery"
        competency33="Google Cloud Rest API Services"
        subHeader3="Programming Languages, Frameworks, and Libraries:"
        competency34="Python"
        competency35="SQL"
        competency36="React JS"
        competency37="HTML"
        competency38="CSS"
        competency39="Node JS"
        competency40="Bootstrap"
        competency41="Plotly"
        competency42="Streamlit"
      />

    </div>
  );
}

export default App;
