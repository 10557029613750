import BulletsRow from "./BulletsRow"

function Competencies(props) {

    return(

        <div id="Competencies" class="section">
        <h1 class="section-heading">{props.header}</h1>

        <div class="sub-section">
            <div class="sub-section-heading">
                <strong>{props.subHeader1}</strong>
            </div>
            <BulletsRow 
                listItem1={props.competency1}
                listItem2={props.competency2}
                listItem3={props.competency3}
            />
            <BulletsRow
                listItem1={props.competency4}
                listItem2={props.competency5}
                listItem3={props.competency6}
            />
            <BulletsRow
                listItem1={props.competency7}
                listItem2={props.competency8}
                listItem3={props.competency9}
            />
            <BulletsRow
                listItem1={props.competency10}
                listItem2={props.competency11}
                listItem3={props.competency12}
            />
        </div>

        <div class="sub-section">
            <div class="sub-section-heading">
                <strong>{props.subHeader2}</strong>
            </div>
            <BulletsRow 
                listItem1={props.competency13}
                listItem2={props.competency14}
                listItem3={props.competency15}
            />
            <BulletsRow
                listItem1={props.competency16}
                listItem2={props.competency17}
                listItem3={props.competency18}
            />
            <BulletsRow
                listItem1={props.competency19}
                listItem2={props.competency20}
                listItem3={props.competency21}
            />
            <BulletsRow
                listItem1={props.competency22}
                listItem2={props.competency23}
                listItem3={props.competency24}
            />
            <BulletsRow
                listItem1={props.competency25}
                listItem2={props.competency26}
                listItem3={props.competency27}
            />
            <BulletsRow 
                listItem1={props.competency28}
                listItem2={props.competency29}
                listItem3={props.competency30}
            />
            <BulletsRow 
                listItem1={props.competency31}
                listItem2={props.competency32}
                listItem3={props.competency33}
            />
        </div>
       
        <div class="sub-section">
            <div class="sub-section-heading">
                <strong>{props.subHeader3}</strong>
            </div>
            <BulletsRow
                listItem1={props.competency34}
                listItem2={props.competency35}
                listItem3={props.competency36}
            />
            <BulletsRow
                listItem1={props.competency37}
                listItem2={props.competency38}
                listItem3={props.competency39}
            />
            <BulletsRow
                listItem1={props.competency40}
                listItem2={props.competency41}
                listItem3={props.competency42}
            />
        </div>

    </div>

    )

}

export default Competencies;
