function Heading(props) {

    return(

        <div id="Heading" class="section">
            <h1 class="name-heading">&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;{props.name}</h1>
            <ul class="profile-list">
                <li class="profile-items">
                    <a class="profile-links" href="https://www.linkedin.com/in/derek-evans01?original_referer=derek-evans01/" alt="LinkedIn Profile Link" title="Link to LinkedIn Profile: https://www.linkedin.com/in/derek-evans01">
                        <strong>LinkedIn Profile</strong>
                    </a>
                </li>
                <li class="profile-items">
                    <a class="profile-links" href="https://www.techbyderek.com/" alt="Text" title="New Portfolio Site (Currently Undergoing Development): https://www.techbyderek.com/">
                        <strong>Portfolio Site</strong>
                    </a>
                </li>
                <li class="profile-items">
                    <a class="profile-links" href="https://derekevansonnotion.notion.site/Career-Portfolio-by-Derek-Evans-f11b2a955d9445e79ce0433021e37410?pvs=4" alt="Legacy Portfolio Link" title="Link to Legacy Portfolio Hosted on Notion: https://derekevansonnotion.notion.site/Career-Portfolio-by-Derek-Evans-f11b2a955d9445e79ce0433021e37410?pvs=4">
                        <strong>Legacy Portfolio</strong>
                    </a>
                </li>
            </ul>
        </div>

    )
}

export default Heading;