function BulletsRow(props) {

    return(
        <div class="bullets">
            <ul class="list-row">
                <li class="grid-item">{props.listItem1}</li>
                <li class="grid-item">{props.listItem2}</li>
                <li class="grid-item">{props.listItem3}</li>
            </ul>
        </div>
    )

}

export default BulletsRow;
