function Summary(props) {

    return(

        <div id="Summary" class="section">
            <h1 class="section-heading">{props.header}</h1>
            <p>{props.body}</p>
        </div>

    )

}

export default Summary;
