import School from "./School"

function Education(props) {

    return(

        <div id="Summary" class="section">
            <h1 class="section-heading">{props.header}</h1>
            <School 
                heading={props.subHeading1}
                school={props.body1}
            />            
            <School 
                heading={props.subHeading2}
                school={props.body2}
            />
        </div>

    )

}

export default Education;
